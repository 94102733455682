import {
  BaseService,
  type BaseServiceContext,
  arrayEqual,
  atomWithCompare,
} from '@bangle.io/base-utils';
import { SERVICE_NAME } from '@bangle.io/constants';
import type { WorkspaceInfo } from '@bangle.io/types';
import { type WsFilePath, WsPath } from '@bangle.io/ws-path';
import { atom } from 'jotai';
import { atomEffect } from 'jotai-effect';
import { unwrap } from 'jotai/utils';
import type { FileSystemService } from './file-system-service';
import type { NavigationService } from './navigation-service';
import type { WorkspaceOpsService } from './workspace-ops-service';

const EMPTY_FILE_PATH_ARRAY: WsFilePath[] = [];
const EMPTY_STRING_ARRAY: string[] = [];

/**
 * Manages the state of current and available workspaces
 */
export class WorkspaceStateService extends BaseService {
  static deps = ['navigation', 'fileSystem', 'workspaceOps'] as const;

  $workspaces = unwrap(
    atom<Promise<WorkspaceInfo[]>>(async (get) => {
      get(this.workspaceOps.$workspaceInfoListChange);
      return this.atomHandleAppError(this.workspaceOps.getAllWorkspaces(), []);
    }),
    (prev): WorkspaceInfo[] => prev || [],
  );

  $wsName = atom<string | undefined>((get) => get(this.navigation.$wsName));

  private $rawWsPaths = atomWithCompare<string[]>(
    EMPTY_STRING_ARRAY,
    arrayEqual,
  );

  $wsPaths = atom<WsFilePath[]>((get) => {
    return get(this.$rawWsPaths)
      .map((path) => WsPath.fromString(path).asFile())
      .filter((path) => path !== undefined);
  });
  $activeWsPaths = atom<WsFilePath[]>((get) => {
    const wsPath = get(this.navigation.$wsFilePath);
    return wsPath ? [wsPath] : EMPTY_FILE_PATH_ARRAY;
  });

  /**
   * This atom is used to check if the current note path
   * is on the disk.
   * TODO: rename to currentWsFilePath
   */
  $currentWsPath = atom((get) => {
    const wsPath = get(this.navigation.$wsFilePath);
    const rawWsPaths = get(this.$rawWsPaths);
    return wsPath && rawWsPaths.includes(wsPath.wsPath) ? wsPath : undefined;
  });

  /**
   * This atom is used to check if the current workspace even exists.
   */
  $currentWsName = atom((get) => {
    const wsName = get(this.$wsName);
    const workspaces = get(this.$workspaces);
    return workspaces.find((ws) => ws.name === wsName)?.name;
  });

  resolveAtoms() {
    return {
      workspaces: this.store.get(this.$workspaces),
      wsPaths: this.store.get(this.$wsPaths),
      currentWsName: this.store.get(this.$currentWsName),
    };
  }

  constructor(
    context: BaseServiceContext,
    private dep: {
      navigation: NavigationService;
      fileSystem: FileSystemService;
      workspaceOps: WorkspaceOpsService;
    },
  ) {
    super(SERVICE_NAME.workspaceStateService, context, dep);
  }

  async hookMount(): Promise<void> {
    this.addCleanup(
      this.store.sub(
        atomEffect((get, set) => {
          const abortController = new AbortController();
          get(this.fileSystem.$fileTreeChangeCount);
          const wsName = get(this.$wsName);

          if (!wsName) {
            return;
          }
          this.atomHandleAppError(
            this.fileSystem.listFiles(wsName, abortController.signal),
            EMPTY_STRING_ARRAY,
          ).then((paths) => {
            if (!abortController.signal.aborted) {
              set(this.$rawWsPaths, paths);
            }
          });
          return () => {
            abortController.abort();
          };
        }),
        () => {},
      ),
    );
  }

  private get navigation() {
    return this.dep.navigation;
  }

  private get fileSystem() {
    return this.dep.fileSystem;
  }

  private get workspaceOps() {
    return this.dep.workspaceOps;
  }
}
